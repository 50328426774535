exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/blog-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-ble-homekit-sensor-pt-1-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/ble-homekit-sensor-pt1/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-ble-homekit-sensor-pt-1-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-ble-homekit-sensor-pt-2-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/ble-homekit-sensor-pt2/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-ble-homekit-sensor-pt-2-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-ble-homekit-sensor-pt-3-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/ble-homekit-sensor-pt3/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-ble-homekit-sensor-pt-3-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-friendly-neighbor-announce-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/friendly-neighbor-announce/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-friendly-neighbor-announce-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-is-this-thing-still-on-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/is-this-thing-still-on/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-is-this-thing-still-on-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-lessons-from-xerox-parc-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/lessons-from-xerox-parc/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-lessons-from-xerox-parc-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-linux-home-server-auto-sleep-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/linux-home-server-auto-sleep/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-linux-home-server-auto-sleep-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-linux-time-machine-server-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/linux-time-machine-server/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-linux-time-machine-server-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-openwrt-iot-firewall-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/openwrt-iot-firewall/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-openwrt-iot-firewall-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-raspberry-pi-internet-router-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/raspberry-pi-internet-router/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-raspberry-pi-internet-router-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-understanding-vax-certs-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/understanding-vax-certs/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-understanding-vax-certs-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-welcome-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/dgross.ca/dgross.ca/content/posts/welcome/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-dgross-ca-dgross-ca-content-posts-welcome-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tag-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tags-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

