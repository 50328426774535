import { merge } from "theme-ui"
import baseTheme from '@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui'
import { tailwind } from "@theme-ui/preset-tailwind"

export default merge(baseTheme, {
  colors: {
    text: tailwind.colors.black,
    primary: tailwind.colors.blue[6],
    altBackground: tailwind.colors.gray[1],
    callout: tailwind.colors.yellow[1],
    modes: {
      dark: {
        primary: tailwind.colors.blue[4],
        altBackground: tailwind.colors.gray[8],
        callout: '#332701',
      },
    },
  },
  fontSizes: ['0.75rem', '1rem', '1.1rem', '1.3rem', '1.6rem', '1.8rem', '2rem', '2.6rem', '3.5rem'],
  fontWeights: {
    heading: 500,
    bold: 600,
  },
  fonts: {
    body: `"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  layout: {
    content: {
      figure: {
        margin: null, // override margin:0 from theme
      },
    },
  },
  styles: {
    strong: {
      fontWeight: 'bold' // Pick up the `bold` setting from `fontWeights` above
    }
  },
  messages: {
    callout: {
      backgroundColor: 'callout',
      borderWidth: 0,
    }
  }
})